.nav_main {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  height: 100%;
  /* justify-content: space-between; */
}

.headerbar {
  background-color: #055646;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0px 4px 12px rgb(60 72 82 / 25%);
  z-index: 9;
  height: 70px;
}

.navbarheading {
  text-align: center;
  font-size: 34px;
  font-weight: 700;
  color: #3c4852;
}

.buttonlogin {
  background-color: #0f5644;
  height: 48px;
  width: 98px;
  border-radius: 8px;
  border: none;
  padding: 0;
}

.buttonlogin p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
}

.loginbutton {
  text-decoration: none;
  padding-right: 4%;
}

.top_heading {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
  padding-left: 110px;
}
.HeaderDiv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 87%;
}
.PopButton {
  color: white !important;
  width: 200px !important;
  height: 30px !important;
  font-size: 20px !important;
  font-family: "Source Sans Pro" !important;
  font-weight: 600 !important;
  margin-top: -4px !important;
  text-transform: capitalize !important;
}
.Popitem {
  width: 200px !important;
  font-size: 16px !important;
  font-family: "Source Sans Pro" !important;
  font-weight: 600 !important;
}
.PopitemMain {
  width: 180px !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  width: 200px !important;
}
