.modalDarkBg {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(33, 42, 57, 0.55);
  backdrop-filter: blur(18px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.formHolder {
  height: auto;
  width: 600px;
  max-width: 890px;
  padding: 32px 24px;
  background-color: white;
  border-radius: 16px;
  position: relative;
}
.radioButtonHolder_program_tag {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  max-width: 104px;
}
.radioButtonHolder_program_tag div {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-start;
}
.radioButtonHolder_program_tag label {
  margin: 0px !important;
}
.radioButtonHolder_program_tag input {
  margin: 0px !important;
}
.crossArrow {
  position: absolute;
  top: 16px;
  right: 16px;
}
.inputField_program_tag {
  border: 1px solid #e0e0e0;
  width: 300px;
  border-radius: 8px;
  overflow: hidden;
  padding: 0px 8px;
}
.inputField_program_tag input[type="number"] {
  width: 100%;
  border: none;
  height: 32px;
  outline: none;
}
.rs-anim-fade.rs-anim-in {
  left: 31vw !important;
  top: 54vh !important;
  position: fixed;
  z-index: 1000000;
}
.submit_button {
  height: 56px;
  width: 200px;
  background-color: #035742;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  color: #ffff;
  margin-top: 16px;
  border: none;
}
