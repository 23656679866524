.main_container_edit {
  padding: 0 0 0 60px;
}

.main_container_edit_viewCourse {
  padding: 0px 60px 60px 60px;
}

.program_name_container {
  padding: 0px 10px 40px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(60, 72, 82, 0.25);
}

.program_name_container_deploymentdetail {
  padding: 0px 10px 40px 10px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid rgba(60, 72, 82, 0.25);
}

.program_name {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 56px;
  display: flex;
  align-items: center;
  color: #3c4852;
}

.program_dropdown {
  width: 289px;
  height: 48px;
  left: 717px;
  top: 192px;
  background: #ffffff;
  border: 1px solid rgba(33, 42, 57, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 10px;
}

.program_page {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #097252;
  padding-top: 24px;
  margin-bottom: 16px;
}

.program_container_inside {
  margin: 18px 0px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.program_container_box {
  width: 850px;
  background: rgba(9, 114, 82, 0.08);
  border-radius: 8px;
  gap: 25px;
  padding: 20px 16px;
}

.program_container_box2 {
  width: 850px;
  background: rgba(9, 114, 82, 0.08);
  border-radius: 8px;
  gap: 25px;
  padding: 20px 16px;
  display: flex;
  align-items: baseline;
}

.program_container_box h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #3c4852;
  opacity: 0.75;
}

.program_container_box h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #3c4852;
  word-break: break-word;
}

.program_container_box2 h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #3c4852;
  opacity: 0.75;
}

.program_container_box2 h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #3c4852;
  word-break: break-word;
}

.program_container_box_copy {
  max-width: 900px;
  width: 100%;
  background: rgba(9, 114, 82, 0.08);
  border-radius: 8px;
  gap: 25px;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
}

.program_container_box_copy h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #3c4852;
  opacity: 0.75;
  overflow-wrap: anywhere;
}

.check_box {
  height: 24px;
  width: 24px;
}

.floater_edit {
  height: 62px;
  background: #3c4852;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  padding: 16px 28px 16px 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 80vw;
  bottom: 10px;
  border-radius: 8px;
}

.floater_edit p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.floater_button {
  min-width: 152px;
  padding: 0px 14px;
  height: 48px;
  background: #ffffff;
  border-radius: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floater_button p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #035642;
}

.main_container_divider {
  /* display: flex; */
  flex-direction: row;
  /* height: 100vh; */
  padding-bottom: 50px;
}

.main_container_divider_approval {
  display: flex;
  flex-direction: row;
  /* height: 100vh; */
  padding-bottom: 50px;
}

.main_container_divid1 {
  width: 50%;
  padding: 0px 35px 0px 10px;
  border-right: 1px solid rgba(33, 42, 57, 0.25);
}

.main_container_divide2 {
  padding: 0 10px 0px 35px;
  width: 50%;
}

.change_content {
  border-bottom: 1px solid rgba(33, 42, 57, 0.25);
  padding-bottom: 10px;
}

.change_content p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #3c4852;
}

.request_approval {
  margin-top: 34px;
}

.request_approval_btn {
  width: 210px;
  height: 48px;
  background: #035642;
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  cursor: pointer;
}

.enquireFormInputedit {
  height: 48px;
  width: 80% !important;
  border: 1px solid rgba(33, 42, 57, 0.25);
  box-sizing: border-box;
  border-radius: 4px;
}

.enquireFormplace {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 56px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #3c4852;
}

.change_content_box {
  width: 493px;
  background: #ecf4f2;
  border-radius: 8px;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.change_content_box_deployementdetail {
  width: 100%;
  background: #ecf4f2;
  border-radius: 8px;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.change_content_box_deployementdetail p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: rgba(60, 72, 82, 0.75);
}

.change_content_box_deployementdetail h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #3c4852;
  word-break: break-word;
}

.change_content_box p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: rgba(60, 72, 82, 0.75);
}

.change_content_box h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #3c4852;
  word-break: break-all;
}

.enquireFormplace2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* line-height: px; */
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #3c4852;
  /* padding-bottom: 10px; */
}

.change_content_box_full {
  width: 800px;
  background: #ecf4f2;
  border-radius: 8px;
  padding: 10px 20px;
  margin-bottom: 15px;
}

.change_content_box_full p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: rgba(60, 72, 82, 0.75);
}

.change_content_box_full h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #3c4852;
}

.add_news {
  width: 180px;
  height: 48px;
  background-color: #035642;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
}

.news_heading_buttondiv {
  display: flex;
  justify-content: space-between;
}

.news_model_container {
  display: flex !important;
  justify-content: center !important;
  width: 1100px !important;
  text-align: center !important;
  align-items: center !important;
  left: 16% !important;
}

.news_model_container > .modal-dialog {
  width: 1100px;
}

.newsFormInput {
  height: 48px;
  width: 828px !important;
  border: 1px solid rgba(33, 42, 57, 0.25);
  box-sizing: border-box;
  border-radius: 4px;
}

.edit_program_json {
  height: 56px;
  width: 186px;
  background-color: #035642;
  color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  border: none;
  font-weight: 600;
}

.programjsoninstruction {
  display: flex;
  text-align: initial;
}

.programjsoninstruction > ul > li {
  text-decoration: dotted;
}

.main_container_edit_viewCourse_2screen {
  border-top: 1px solid rgba(60, 72, 82, 0.25);
}

.program_page_content {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #097252;
  padding-top: 40px;
  margin-bottom: 16px;
}

.program_container_box_content {
  width: 900px;
  background: rgba(9, 114, 82, 0.08);
  border-radius: 8px;
  gap: 25px;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.enquireFormInputEditUtm {
  height: 48px;
  width: 600px !important;
  border: 1px solid rgba(33, 42, 57, 0.25);
  box-sizing: border-box;
  border-radius: 4px;
}

.enquireFormplaceutm {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #3c4852;
  padding-bottom: 10px;
}

.copybtndivutmdetail {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: rgb(5, 86, 70);
}
