.data_card {
  width: 40%;
  border-radius: 8px;
  margin: 60px 40px 40px 40px;
  text-align: initial;
  border: 2px solid #3c4852;
  padding: 20px;
}

.data_card p {
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 5px;
}

.data_card span {
  font-size: 20px;
  font-weight: 600;
}

.edit_button_main {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 20px;
}

.edit_button_main button {
  width: 100px;
  height: 40px;
  border-radius: 4px;
  border: none;
  background-color: #0f5644;
  color: #fff;
  cursor: pointer;
}
