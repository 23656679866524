.coursecontainer {
  text-align: center;
}

.enquire_box_space {
  margin-bottom: 18px;
}

.enquireFormheading {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 28px;
}

.form-inpt-contnr .form-inpt {
  font-size: 16px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  width: 50%;
}

.enquireFormInput,
.enquireFormInput2 {
  height: 48px;
  border: 1px solid rgba(33, 42, 57, 0.25);
  box-sizing: border-box;
  border-radius: 4px;
}

.enquireFormButton {
  width: 416px;
  height: 56px;
  background: #035642;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  outline: none;
  border: none;
  cursor: pointer;
}

.form-submit-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
}
