.create_auxinput {
  font-size: 18px;
  padding: 8px 16px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  height: 48px;
  width: 80%;
  border: 1px solid rgba(33, 42, 57, 0.25);
  box-sizing: border-box;
  margin-bottom: 20px;
}

.create_auxinput2 {
  font-size: 18px;
  padding: 8px 16px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  border: 1px solid rgba(33, 42, 57, 0.25);
  box-sizing: border-box;
  margin-bottom: 20px;
}

.create_auxinput3 {
  font-size: 18px;
  padding: 8px 16px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  border: 1px solid rgba(33, 42, 57, 0.25);
  box-sizing: border-box;
  margin-bottom: 6px;
}

.note_label {
  color: rgba(255, 0, 0, 0.623);
  margin-bottom: 20px;
}
