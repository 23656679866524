@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
}

code {
  font-family: "Source Sans Pro", sans-serif;
}
