.checkboxAndQualificationHolder {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 16px;
}
.changeProgramContainer {
  height: auto;
  width: 50%;
  margin-top: 24px;
  background-color: rgba(9, 114, 82, 0.08);
  padding: 16px;
  border-radius: 8px;
}
.ProgramCheckbox {
  max-width: 300px;
  height: 50px;
  color: #3c4852;
  border: none;
  padding: 0px 8px;
  border: 1px solid #3c4852;
  border-radius: 8px;
  background: #fff;
}
.ProgramCheckbox > select {
  max-width: 284px;
  height: 100%;
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

.checkboxAndExperienceHolder {
  display: flex;
  align-items: baseline;
  gap: 30px;
}

.submitButton {
  height: 56px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #055646;
  color: #fff;
  margin: 16px 0px 0px 0px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}
