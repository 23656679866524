.home_title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #097252;
  padding-bottom: 16px;
}

/* .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.modal-open {
  overflow-y: hidden;
} */

.home_container {
  padding: 0 10px 0 10px;
}

.home_title_div {
  border-bottom: 1px solid rgba(60, 72, 82, 0.25);
}

.main-container_home {
  padding-top: 60px;
  margin-left: 18%;
}

.home_title_div {
  margin-bottom: 24px;
}

.home_table th {
  width: 9%;
}

.uper_table_container {
  height: 50px;
  border: 2px solid black;
  text-align: justify;
}

.uper_table_container th {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* text-transform: capitalize; */
  color: #3c4852;
  opacity: 0.9;
}

.uper_table_container_field {
  white-space: nowrap;
}

.deployment_btn {
  border: none;
  border-radius: 8px;
  background-color: #097252;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  height: 56px;
  padding: 0 15px 0 15px;
}

.deployment_btn_div {
  margin-bottom: 20px;
  text-align: center;
}

.create_location_page_div {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.create_location_button {
  border: none;
  border-radius: 8px;
  background-color: #097252;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  height: 56px;
  padding: 0 15px 0 15px;
}

.utm_home_container {
  padding: 0 30px 10px 10px;
}

.createUtmmandatory {
  color: red;
}

.createUtmIbutton {
  color: #9da4a9;
  cursor: pointer;
  position: relative;
}

.createUtmIbutton1 {
  color: #9da4a9;
  cursor: pointer;
  position: relative;
}

.createUtmibtnotp {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  margin-left: 10px;
  text-align: initial;
  width: 300px;
}

.createUtmibtcallcenter {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  margin-left: 10px;
  text-align: initial;
  width: 300px;
}

.createUtmIbutton:hover .createUtmibtnotp {
  visibility: visible;
}

.createUtmIbutton1:hover .createUtmibtcallcenter {
  visibility: visible;
}

.createUtmmaindiv {
  display: flex;
  flex-direction: row;
}

.createUtmbottomhalf {
  width: 50%;
}

.createUtmbottomhalf2 {
  width: 500px;
}

.createUtmbottomhalf3 {
  width: 100%;
}

.utmpreviewstick {
  position: -webkit-sticky;
  position: sticky;
  top: 95px;
}

.floater_edit_program {
  height: 62px;
  background: #3c4852;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  padding: 16px 28px 16px 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 71vw;
  bottom: 10px;
  border-radius: 8px;
}

.floater_edit_program p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.main_container_edit_viewCourse_attribute {
  padding: 0px 30px 30px 30px;
}

.viewlocationpage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #097252;
  color: #fff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.programnameplusbutton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.newLocationInput {
  height: 48px;
  width: 400px !important;
  border: 1px solid rgba(33, 42, 57, 0.25);
  box-sizing: border-box;
  border-radius: 4px;
}

.hovertextlocation {
  text-decoration: none;
  padding: 4px;
  border-radius: 8px;
  color: #fff;
  background-color: #3c4852;
  width: 200px;
  margin-top: 8px;
  display: none;
  position: absolute;
}

.viewlocationpage:hover ~ .hovertextlocation {
  display: block;
}

.addprogrambtn {
  width: 199px;
  height: 48px;
  border-radius: 8px;
  background-color: #035642;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}

.home_program_div {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.startProgramCreation {
  width: auto;
  height: 48px;
  border-radius: 8px;
  background-color: #035642;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
  padding: 0px 16px 0px 16px;
  margin-left: 32px;
}

.addppglisttext {
  font-size: 20px;
  font-weight: 600;
  color: #097252;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(60, 72, 82, 0.25);
  /* width: 90%; */
  margin-bottom: 24px;
}

.add_program_table {
  height: 50px;
  /* border: 2px solid #D9DADC; */
  background-color: #d9dadc;
  text-align: justify;
  border-radius: 8px;
}

.addpgtabletr {
  border: 0.5px solid #3c4852;
}

.addpg2btncon {
  display: flex;
  gap: 24px;
}

.model_container_addprogram {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
  left: 5% !important;
}

.addprograminput {
  height: 48px;
  border: 1px solid rgba(33, 42, 57, 0.25);
  box-sizing: border-box;
  border-radius: 4px;
}

.addprogrambtnstart {
  height: 48px;
  background: #035642;
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  cursor: pointer;
  width: 250px;
}

.addprogrambtndiv {
  display: flex;
  flex-direction: row;
  justify-content: end;
  border-top: 1px solid rgba(60, 72, 82, 0.25);
  padding-top: 16px;
}

.model_container_addprogram > .modal-dialog {
  width: 674px;
}

.addprogrammodelheader {
  text-align: initial;
  color: var(--097252, #097252);
  font-size: 20px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.template-create-page > .panel-danger {
  display: none;
}

/* media uploader css */

.folder_grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: normal;
  gap: 30px;
}
.folder_grid_inside {
  display: flex;
  flex-direction: column;
  justify-content: normal;
  text-align: center;
}
.folderlogo {
  height: 67px !important;
  width: 67px !important;
}
.foldername {
  margin-left: 0px;
}
.image-grid-structure {
  display: flex;
  flex-direction: row;
}
.grid-left {
  width: 80%;
}
.grid-right {
  width: 20%;
  padding-top: 24px;
  padding-left: 32px;
}
.copyicon {
  cursor: pointer;
}
.preview p {
  color: #212a39;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.preview-image {
  width: 211px;
  height: 211px;
  padding-top: 24px;
  background-position: 50% center;
  background-size: contain;
}
.preview {
  cursor: pointer;
}

.filter_container {
  display: flex;
  gap: 20px;

  border-bottom: 1px dashed rgba(60, 72, 82, 0.25);
  margin-bottom: 18px;
  padding-bottom: 16px;
}

.filter_title {
  font-size: 18px;
}

.filter_field {
  display: flex;
  align-items: center;
}

.filter_field input {
  margin-left: 10px;
}

.filter_field > label {
  display: flex;
  gap: 10px;
}

.filter_search {
  cursor: pointer;

  background-color: rgb(5, 86, 70);
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
}

.updatedFieldsflex {
  display: flex;
  gap: 10px;

  padding-bottom: 20px;
}

.updatedFieldsLabel {
  font-weight: 600;
}

.updatedFieldsLKey {
  margin: 0;
  padding: 0;
  color: red;
}

.active_inactive_flex {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  border-bottom: 1px solid #e7e7e7;
}

.active_inactive_tab {
  cursor: pointer;
  width: 50%;
  padding: 10px;
}

.active_inactive_tab p {
  width: 100%;
  color: #035642;
  font-weight: 600;
  text-align: center;
}
