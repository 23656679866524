.webinar_pop_main {
  position: relative;
  display: inline-block;
}
.webinar_pop {
  width: 600px;
  background: rgba(65, 64, 66, 0.05);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 10;
  top: 25%;
  left: 60%;
  margin-left: -80px;
}
.header {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #035642;
  border-bottom: 1px solid #035642;
  margin-bottom: 20px;
  width: 300px;
}
.button {
  width: 152px;
  height: 48px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #035642;
}
.button p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  color: #035642;
}
.webinar-subheading {
  margin: -20px 0px 0px -50px;
}

.webinar-subheading-location {
  margin: -20px 0px 0px 0px;
}

.webinar-subheading-div {
  margin: -20px 0px 0px -50px;
}
.webinar-speaker2 > fieldset > legend {
  display: none;
}
.webinar-subheading > div > label > span {
  display: none;
}

.webinar-subheading-location > div > label > span {
  display: none;
}

.webinar-subheading-div > div > label > span {
  display: none;
}
.webinar-subheading-main > fieldset > legend {
  border-bottom: none;
}

.webinar-subheading-main-location > fieldset > legend {
  border-bottom: none;
  padding-left: 30px;
}

.webinar-subheading1 {
  margin: -25px 0px 0px;
}
.webinar-subheading1 > label {
  display: none;
}

.webinar-subheading-location1 {
  margin: -25px 0px 15px 0px;
}
.webinar-subheading-location1 > label {
  display: none;
}

.webinar-subheading2 {
  margin: 0px 0px 10px 0px;
}
.webinar-ImageTab {
  margin: 0px 0px 10px 0px;
}
.webinar-subheading > div > label > input {
  width: 24px;
  height: 24px;
  margin-top: -32px;
}

.webinar-subheading-location > div > label > input {
  width: 24px;
  height: 24px;
  margin-top: -35px;
}

.webinar-subheading-div > div > label > input {
  width: 24px;
  height: 24px;
  margin-top: -56px;
}
.webinar-speaker {
  margin: -30px 0px 0px 0px;
}
.webinar-speaker > fieldset > legend {
  display: none;
}
.webinar-speaker1 {
  margin: -30px 0px 0px 0px;
}
.webinar-main-heading > fieldset > legend {
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
  color: #097252;
  border-bottom: none;
}
.webinar-image-main {
  margin: -30px 0px 0px 0px;
}
.webinar-image-main > fieldset > legend {
  border-bottom: none;
  display: none;
}
.webinar-speaker-previous {
  margin: -30px 0px 0px 0px;
}
.webinar-speaker-previous > fieldset > legend {
  display: none;
}
.webinar-speaker-previous > fieldset > div:nth-child(3) {
  display: none;
}
.webinar-speaker-previous
  > fieldset
  > div:nth-child(2)
  > div
  > div:nth-child(2) {
  display: none;
}
.webinar-speaker-previous1 > fieldset > legend {
  display: none;
}
.webinar-speaker-previous1 > fieldset > div:nth-child(3) {
  display: none;
}
.webinar-speaker-previous1
  > fieldset
  > div:nth-child(2)
  > div
  > div:nth-child(2) {
  display: none;
}
.webinar-subheading-main > fieldset > p {
  margin-top: -10px;
  padding-bottom: 10px;
  font-size: 18px;
  color: #055646;
}

.webinar-subheading-main-location > fieldset > p {
  margin-top: -10px;
  padding-bottom: 10px;
  font-size: 18px;
  color: #055646;
}

.webinar-disable1 {
  margin-top: -24px;
}
.webinar-disable1 > label {
  display: none;
}
.webinar-disable1 > div {
  margin-left: 16px;
}
.webinar-disable1 > div > div > label > span > input {
  width: 16px;
  height: 16px;
}
.webinar-disable1 > div > div > label > span > input {
  width: 16px;
  height: 16px;
  margin-top: 2px;
}
.webinar-disable1 > div > div > label > span > span {
  font-size: 16px;
  margin-left: 4px;
  margin-top: 8px;
}
.webinar-disable {
  margin: -60px 0px 0px -50px;
}
.webinar-disable > div > label > inpuT {
  width: 24px;
  height: 24px;
}
.webinar-disable > div > label > span {
  display: none;
}
.Setting-heading {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: black;
  padding-bottom: 16px;
}
.Main-div {
  margin-left: 2vw;
  padding-bottom: 64px;
  /* padding-top: -60px; */
  margin-top: -60px;
}
.Setting-data-div {
  background-color: green;
  width: 94%;
  height: 500px;
  border-radius: 8px;
  background-color: rgb(245, 245, 245);
  overflow-y: scroll;
}
.Setting-data-div1 {
  padding: 32px 40px;
}
.Main-div1 {
  margin-top: 56px;
}

.header_location {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  text-decoration: underline;
  color: #035642;
  margin-bottom: 24px;
}

.locationprogramacchead {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #035642;
}

.master-template-sectionshufleName {
  display: none;
}

.master-template-sectionshufleimg > label {
  display: none;
}

.master-template-sectionshufleimg > input {
  display: none;
}

.template-main-heading > fieldset > legend {
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  border-bottom: none;
  background-color: rgba(58, 186, 168, 0.1);
  height: 57px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  color: #3abaa8;
  border-radius: 8px;
}

.template-subheading-main > fieldset > legend {
  border: none;
  color: #3c4852;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  opacity: 0.75;
  margin-bottom: -10px;
}

.template-subheading2 {
  margin: 16px 0px 10px 0px;
}

.template-subheading2 > div {
  height: 72px;
  border: 1px solid rgba(33, 42, 57, 0.25);
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 20px;
}

.template-ImageTab > div {
  height: 72px;
  border: 1px solid rgba(33, 42, 57, 0.25);
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 20px;
}

.template-ImageTab > div > ul {
  margin-bottom: 0px;
}

.template-subheading2 > div > ul {
  margin-bottom: 0px;
}

.template-ImageTab > div > p > input[type="file"]::file-selector-button {
  width: 155px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid rgba(33, 42, 57, 0.75);
  color: rgba(33, 42, 57, 0.75);
  background-color: #fff;
  margin-right: 16px;
}

.template-subheading2 > div > p > input[type="file"]::file-selector-button {
  width: 155px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid rgba(33, 42, 57, 0.75);
  color: rgba(33, 42, 57, 0.75);
  background-color: #fff;
  margin-right: 16px;
}

.template-checkbox-main > div label {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.template-checkbox-main > div > label > input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.template-checkbox-main > div > label > span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.template-checkbox-main > div > label > span:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.template-checkbox-main > div > label > input:checked + span {
  background-color: rgba(5, 86, 70, 1);
  color: rgba(5, 86, 70, 1);
}

.template-checkbox-main > div > label > input:focus + span {
  box-shadow: 0 0 1px rgba(5, 86, 70, 1);
}

.template-checkbox-main > div > label > input:checked + span:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.template-checkbox-main > div > label > span {
  background-color: #8d8d8d;
  color: #8d8d8d;
  border-radius: 34px;
}

.template-checkbox-main > div > label > span:before {
  border-radius: 50%;
}

.template-main-heading {
  position: relative;
}

.template-checkbox-main > div {
  position: absolute;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  right: 50px;
  top: 2px;
}

.template-create-page > div:nth-child(2) > button {
  height: 48px;
  width: 100px;
  font-size: 16px;
  font-weight: bold;
  background-color: #035642;
  border-radius: 8px;
}

.template-btn-div {
  margin-top: 32px;
  border-top: 1px solid rgba(60, 72, 82, 0.5);
}

.template-btn-preview {
  width: 199px;
  height: 48px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  background-color: #035642;
  border-radius: 8px;
}

.template-btn-approval {
  width: 250px;
  height: 48px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  background-color: rgba(58, 186, 168, 1);
  border-radius: 8px;
}

.template-btns {
  display: flex;
  gap: 20px;
  margin-top: 32px;
}
