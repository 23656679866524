.metaAdsUploadContainer {
  padding: 24px;
  display: flex;
  gap: 16px;
  border-radius: 16px;
  background: #f5f8f8;

  form {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: end;

    .inputs {
      display: flex;
      gap: 24px;
    }

    p {
      font-size: 18px;
      padding-bottom: 4px;
    }

    .metaCsvFileInput {
      border-radius: 8px;
      border: 1px solid rgba(60, 72, 82, 0.24);
      background: #fff;
      padding: 10px;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #3c4852;
    }

    .dateInput {
      display: flex;
      gap: 24px;

      .calender {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        width: 200px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #3c4852;
        padding: 8px;
      }
    }

    .metaAdsUploadContainerBtn {
      width: 166px;
      height: 40px;
      padding: 11px 48px;
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: #035642;
      border-radius: 4px;
      border: none;
      text-wrap: nowrap;
    }
  }
}
